.global{
  font-size: 15px;
  padding: 0;
  margin: 0;
  width: '100%';
  height: '100%';
  overflow: hidden;
}

.wrapper {
  padding: 10px;
}


